const browserWindow: any = window || {};
export const environment = {
  production: false,
  config:{
    contentbaseUrl:  'https://dev2-aisapi.project-turbine.in',
    articlebaseUrl:  'https://dev2-aisapi.project-turbine.in/v1',
    newArticlebaseUrl:  'https://dev2-aisapi.project-turbine.in/v2',
    configUrl: "https://sach-static-content.s3.ap-south-1.amazonaws.com/ai-assist",
    trackingUrl: "https://pkd-dev2-as.project-turbine.in/v1",
    helpMeWriteUrl: "https://am-dev-zti.project-turbine.in/v1",
    authenticationUrl: 'https://dev.project-turbine.in',
    basicAuth:{
      userName:"admin",
      password:"Y%9${+X42k+.Oa9H8ZiMvCuL1D2EONuNqYiA5IS?}z"
    }
  }
};
